import "../Style/ServList.css";

export default function ServList({ updatePage }) {
  const one = () => {
    updatePage(1);
  };
  const two = () => {
    updatePage(2);
  };
  const three = () => {
    updatePage(3);
  };
  const four = () => {
    updatePage(4);
  };
  const five = () => {
    updatePage(5);
  };
  const six = () => {
    updatePage(6);
  };
  const seven = () => {
    updatePage(7);
  };
  const eight = () => {
    updatePage(8);
  };
  const nine = () => {
    updatePage(9);
  };
  const ten = () => {
    updatePage(10);
  };
  const eleven = () => {
    updatePage(11);
  };
  const twelve = () => {
    updatePage(12);
  };
  const thirteen = () => {
    updatePage(13);
  };

  return (
    <div className="serv-list">
      <div className="srl1">Гражданские дела</div>
      <div className="list">
        <button onClick={two} className="litem">
          Земельные споры
        </button>
        <button onClick={three} className="litem">
          Наследственные споры
        </button>
        <button onClick={five} className="litem">
          Жилищные споры
        </button>
        <button onClick={six} className="litem">
          Медиация
        </button>
        <button onClick={four} className="litem">
          Семейные споры
        </button>
        <button onClick={eight} className="litem">
          Споры по инфобизнесу
        </button>
        <button onClick={nine} className="litem">
          Споры с государствеными органами
        </button>
        <button onClick={seven} className="litem">
          Корпоративные споры
        </button>
        <button onClick={ten} className="litem">
          Имущественные споры
        </button>
      </div>

      <div className="srl2">уголовные дела</div>
      <div className="list">
        <button onClick={twelve} className="litem">
          Экономическое право
        </button>
        <button onClick={eleven} className="litem">
          Коррупционное право
        </button>
        <button onClick={one} className="litem">
          Преступления против собственности
        </button>
      </div>
    </div>
  );
}
