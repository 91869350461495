import experts from "../Assets/main/experts1.svg";
import NavBar from "../Components/NavBar";
import telegramIcon from "../Assets/main/tlg.png";
import icon from "../Assets/logo.svg";
import und from "../Assets/main/image 68 (Traced).svg";
import "../Style/Main.css";

export default function Main({ updateForm }) {
  const submit = () => {
    updateForm();
  };

  return (
    <div className="main-page" id="Main">
      <div className="content">
        <NavBar></NavBar>
        <img src={icon} className="icon" />
        <a href="https://wa.me/77051916994?text=" className="number">
          +7 705 191 6994
        </a>
        <div className="main-body">
          <div className="m1">Лучшее Юридическое Агентство</div>
          <div className="m2">TVOE PRAVO</div>
          <div className="m3">ЛЕГко решаем сложные вопросы</div>
          <div className="m4">
            Мы тщательно анализируем каждую ситуацию, чтобы предложить наилучшее
            решение. С нами вы можете быть уверены в поддержке на каждом этапе
          </div>

          <button onClick={submit} className="m5">
            записаться на консультацию
          </button>

          <div className="m6">
            Наши специалисты
            <br />
            по Гражданским делам:
          </div>
          <img src={experts} className="experts" />
        </div>

        <div className="boxxx gh">
          <div className="telegram-bot">
            <img src={telegramIcon} />
            <div className="telegram-text">
              Обращайтесь к нам в любое время, для получения консультации!
            </div>
          </div>
        </div>

        <div className="boxxx tlgg">
          <div className="telegram-bot second">
            <img src={und} />
            <div className="telegram-text">
              TVOEPRAVO<br></br> Отстаиваем интересы наших клиентов!
            </div>
          </div>
        </div>

        <div className="box">
          <div className="telegram-bot">
            <img src={telegramIcon} />
            <div className="telegram-text">
              Обращайтесь к нам в любое время, для получения консультации!
            </div>
          </div>

          <div className="telegram-bot second">
            <img src={und} />
            <div className="telegram-text">
              TVOEPRAVO Отстаиваем интересы наших клиентов!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
