import Main from "./Components/Main";
import About from "./Components/About";
import Services from "./Components/Services/Services";
import AboutCompany from "./Components/AboutCompany";
import Team from "./Components/Team";
import Feedbacks from "./Components/Feedbacks/Feedbacks";
import Certificates from "./Components/Certificates";
import Contacts from "./Components/Contacts";

import Form from "./Components/Form";
import ServList from "./Components/ServList";

import ServPage from "./Components/Pages/ServPage";
import Page1 from "./Components/Pages/Page1";
import Page2 from "./Components/Pages/Page2";
import Page3 from "./Components/Pages/Page3";
import Page4 from "./Components/Pages/Page4";
import Page5 from "./Components/Pages/Page5";
import Page6 from "./Components/Pages/Page6";
import Page7 from "./Components/Pages/Page7";
import Page8 from "./Components/Pages/Page8";
import Page9 from "./Components/Pages/Page9";
import Page10 from "./Components/Pages/Page10";
import Page11 from "./Components/Pages/Page11";
import Page12 from "./Components/Pages/Page12";
import logo from "./Assets/logo.svg";
import "./Style/App.css";

import React, { useState, useEffect, useRef } from "react";

function App() {
  useEffect(() => {
    document.title = "Tvoe Pravo"; // Change the title

    const favicon = document.querySelector("link[rel='icon']");
    if (favicon) {
      favicon.href = logo; // Dynamically change the favicon
    }
  }, []);

  // Submit Form
  const [form, setForm] = useState(false);
  const containerRef = useRef(null);
  const updateForm = () => {
    setForm(true);
  };

  // Service list
  const [list, setList] = useState(false);
  const listRef = useRef(null);
  const updateList = () => {
    setList(true);
  };

  // Clicking outside of pop-up window
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Clean up event listener
    };
  }, []);

  const handleClickOutside = (event) => {
    if (listRef.current && !listRef.current.contains(event.target)) {
      setList(false); // Update state to close the container if click happens outside
    }

    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setForm(false); // Update state to close the container if click happens outside
    }
  };

  // Current page
  const [currentPage, setCurrentPage] = useState(0);
  const [transitioning, setTransitioning] = useState(false);

  const updatePage = (num) => {
    setList(false);
    setTransitioning(true); // Trigger the animation

    setTimeout(() => {
      setCurrentPage(num + 1); // Change page after the animation
      setTransitioning(false); // Reset the animation state

      // Ensure scroll happens after page content is rendered
      setTimeout(() => {
        if (currentPage === 0) {
          // Scroll to the top of the page for the main page
          window.scrollTo({
            top: 0,
            behavior: "smooth", // Smooth scrolling
          });
        } else {
          // Scroll to the "Services" section if num != 0
          const servicesSection = document.getElementById("Services");
          if (servicesSection) {
            servicesSection.scrollIntoView({
              behavior: "smooth", // Smooth scrolling
              block: "start", // Align the section to the top of the viewport
            });
          }
        }
      }, 100); // Slight delay to ensure new content is loaded
    }, 400); // Duration of the page transition
  };

  const renderPage = () => {
    if (currentPage == 0) {
      return (
        <>
          <div className={form ? "form-open" : "form-close"} ref={containerRef}>
            <Form setOpen={setForm}></Form>
          </div>

          <div className={list ? "list-open" : "list-close"} ref={listRef}>
            <ServList updatePage={updatePage}></ServList>
          </div>

          <div className={form || list ? "blur-whole" : ""}>
            <Main updateForm={updateForm} />
            <About />
            <Services
              updateForm={updateForm}
              updatePage={updatePage}
              updateList={updateList}
            ></Services>
            <AboutCompany updateForm={updateForm}></AboutCompany>
            <Team></Team>
            <Feedbacks></Feedbacks>
            <Certificates></Certificates>
            <Contacts></Contacts>
          </div>
        </>
      );
    }

    if (currentPage == 1) {
      return (
        <>
          <div className={form ? "form-open" : "form-close"} ref={containerRef}>
            <Form></Form>
          </div>

          <div className={form ? "blur-whole" : ""}>
            <ServPage updateForm={updateForm} updatePage={updatePage} />
          </div>
        </>
      );
    }

    if (currentPage == 2) {
      return (
        <>
          <div className={form ? "form-open" : "form-close"} ref={containerRef}>
            <Form></Form>
          </div>

          <div className={form ? "blur-whole" : ""}>
            <Page1 updateForm={updateForm} updatePage={updatePage}></Page1>
          </div>
        </>
      );
    }

    if (currentPage == 3) {
      return (
        <>
          <div className={form ? "form-open" : "form-close"} ref={containerRef}>
            <Form></Form>
          </div>

          <div className={form ? "blur-whole" : ""}>
            <Page2 updateForm={updateForm} updatePage={updatePage}></Page2>
          </div>
        </>
      );
    }

    if (currentPage == 4) {
      return (
        <>
          <div className={form ? "form-open" : "form-close"} ref={containerRef}>
            <Form></Form>
          </div>

          <div className={form ? "blur-whole" : ""}>
            <Page3 updateForm={updateForm} updatePage={updatePage}></Page3>
          </div>
        </>
      );
    }
    if (currentPage == 5) {
      return (
        <>
          <div className={form ? "form-open" : "form-close"} ref={containerRef}>
            <Form></Form>
          </div>

          <div className={form ? "blur-whole" : ""}>
            <Page4 updateForm={updateForm} updatePage={updatePage}></Page4>
          </div>
        </>
      );
    }
    if (currentPage == 6) {
      return (
        <>
          <div className={form ? "form-open" : "form-close"} ref={containerRef}>
            <Form></Form>
          </div>

          <div className={form ? "blur-whole" : ""}>
            <Page5 updateForm={updateForm} updatePage={updatePage}></Page5>
          </div>
        </>
      );
    }
    if (currentPage == 7) {
      return (
        <>
          <div className={form ? "form-open" : "form-close"} ref={containerRef}>
            <Form></Form>
          </div>

          <div className={form ? "blur-whole" : ""}>
            <Page6 updateForm={updateForm} updatePage={updatePage}></Page6>
          </div>
        </>
      );
    }
    if (currentPage == 8) {
      return (
        <>
          <div className={form ? "form-open" : "form-close"} ref={containerRef}>
            <Form></Form>
          </div>

          <div className={form ? "blur-whole" : ""}>
            <Page7 updateForm={updateForm} updatePage={updatePage}></Page7>
          </div>
        </>
      );
    }
    if (currentPage == 9) {
      return (
        <>
          <div className={form ? "form-open" : "form-close"} ref={containerRef}>
            <Form></Form>
          </div>

          <div className={form ? "blur-whole" : ""}>
            <Page8 updateForm={updateForm} updatePage={updatePage}></Page8>
          </div>
        </>
      );
    }
    if (currentPage == 10) {
      return (
        <>
          <div className={form ? "form-open" : "form-close"} ref={containerRef}>
            <Form></Form>
          </div>

          <div className={form ? "blur-whole" : ""}>
            <Page9 updateForm={updateForm} updatePage={updatePage}></Page9>
          </div>
        </>
      );
    }

    if (currentPage == 11) {
      return (
        <>
          <div className={form ? "form-open" : "form-close"} ref={containerRef}>
            <Form></Form>
          </div>

          <div className={form ? "blur-whole" : ""}>
            <Page10 updateForm={updateForm} updatePage={updatePage}></Page10>
          </div>
        </>
      );
    }
    if (currentPage == 12) {
      return (
        <>
          <div className={form ? "form-open" : "form-close"} ref={containerRef}>
            <Form></Form>
          </div>

          <div className={form ? "blur-whole" : ""}>
            <Page11 updateForm={updateForm} updatePage={updatePage}></Page11>
          </div>
        </>
      );
    }
    if (currentPage == 13) {
      return (
        <>
          <div className={form ? "form-open" : "form-close"} ref={containerRef}>
            <Form></Form>
          </div>

          <div className={form ? "blur-whole" : ""}>
            <Page12 updateForm={updateForm} updatePage={updatePage}></Page12>
          </div>
        </>
      );
    }
  };

  return (
    <div
      className={`page-containerrr ${transitioning ? "slide-out" : "slide-in"}`}
    >
      {renderPage()}
    </div>
  );
}

export default App;
