import img2 from "../../Assets/services/Card2.png";
import img from "../../Assets/services/Card1.png";
import img3 from "../../Assets/services/Card3.png";
import img4 from "../../Assets/services/Card4.png";
import img5 from "../../Assets/services/Card5.png";
import img6 from "../../Assets/services/Card6.png";
import img7 from "../../Assets/services/Card7.png";
import img8 from "../../Assets/services/Card8.png";
import img9 from "../../Assets/services/Card10.png";

import ug1 from "../../Assets/ug1.png";
import ug2 from "../../Assets/ug2.png";
import ug3 from "../../Assets/ug3.png";

import line from "../../Assets/line.png";
import "./ServPage.css";

const cards = [
  {
    image: img,
    header: "Земельные споры",
    description:
      "Разрешение споров по поводу владения, пользования, распоряжения, определения границ земельных участков  (раздел земельного участка, изменение категории земельного участка, определение порядка пользования участком, нарушение правил добрососедства, захват чужих территорий и т.д.).",
    pageNumber: 2,
  },
  {
    image: img2,
    header: "Наследственные споры",
    description:
      "Раздел имущества между наследниками; признание права на обязательную долю в наследстве; восстановление пропущенного срока для принятия наследства; оспаривание завещания; признание недостойного наследника; иные наследственные споры.",
    pageNumber: 3,
  },
  {
    image: img3,
    header: "Жилищные споры",
    description:
      "Признание/оспаривание прав собственности на жилое помещение; раздел жилого помещения в суде; вселение/выселение; перевод нежилого помещения в жилое и наоборот; признание сделки купли-продажи/дарения квартиры или иного жилья недействительной; иные споры, связанные с жилим недвижимым имуществом.",
    pageNumber: 5,
  },
  {
    image: img4,
    header: "Медиация",
    description:
      "Оказываем профессиональные услуги медиатора по разрешению споров в досудебном порядке, судебном или исполнительном производствах. Процедура медиации способствует сторонам конфликта принять взаимовыгодное решение.",
    pageNumber: 6,
  },
  {
    image: img5,
    header: "Семейные споры",
    description:
      "Расторжение брака; раздел совместно нажитого имущества; взыскание алиментов; определение места жительства детей; определение порядка общения с детьми; лишение/восстановление родительских прав; установление/оспаривание отцовства.",
    pageNumber: 4,
  },
  {
    image: img6,
    header: "Споры по инфобизнесу",
    description:
      "Правовое сопровождение бизнеса, основанного на продаже информации (курсы, семинары, вебинары, тренинги, конференции, коучинги, консалтинги, франшизы и т.д.).",
    pageNumber: 8,
  },
  {
    image: img7,
    header: "Споры с гос органами",
    description:
      "Обжалование в вышестоящий государственный орган или суд действий/бездействий должностных лиц.",
    pageNumber: 9,
  },
  {
    image: img8,
    header: "Корпоративные споры",
    description: "Правовое сопровождение деятельности юридических лиц.",
    pageNumber: 7,
  },
  {
    image: img9,
    header: "Имущественные споры",
    description:
      "Признание сделки недействительной; возмещение убытков; взыскание суммы задолженности; признание права собственности на имущество; раздел имущества; иные споры, связанные с нежилимым и/или движимым имуществом.",
    pageNumber: 10,
  },
];

const ugcards = [
  {
    image: ug1,
    header: "Экономическое право",
    description:
      "• Незаконное предпринимательство; Экономическая контрабанда; Уклонение от уплаты таможенных пошлин, сборов, налогов и других обязательных платежей в бюджет; Преднамеренное банкротство; Легализация денег и/или иногоимущества, добытого преступным путем; Иные преступления в сфере экономической деятельности",
    pageNumber: 12,
  },
  {
    image: ug2,
    header: "Коррупционное право",
    description:
      "Превышение должностных полномочий; Незаконное участие в предпринимательской деятельности; Получение/дача взятки; Иные коррупционные преступления",
    pageNumber: 11,
  },
  {
    image: ug3,
    header: "Преступления против собственности",
    description:
      "Кража; Мелкое хищение; Мошенничество; Грабеж; Разбой; Вымогательство; Умышленное уничтожение или повреждение чужого имущества; Мные преступления против собственности",
    pageNumber: 1,
  },
];

function Card({ image, header, description, formUp, pgn, pageNumber }) {
  const formD = () => {
    formUp();
  };

  const pg = () => {
    pgn(pageNumber);
  };

  return (
    <div className="service-card">
      <img src={image} alt={header} />
      <div className="header">{header}</div>
      <div className="description">{description}</div>
      <div className="service-card-btns">
        <button onClick={formD} className="b1">
          Обратиться
        </button>
        <button onClick={pg} className="b2">
          Подробнее
        </button>
      </div>
    </div>
  );
}

const ServPage = ({ updateForm, updatePage }) => {
  // To return to the main page
  const back = () => {
    updatePage(-1);
  };

  // Update current page
  const formUp = () => {
    updateForm();
  };

  const pgn = (int) => {
    updatePage(int);
  };

  return (
    <div className="serv-page">
      <div className="content">
        <div className="srv1">Наши Услуги</div>
        <div className="srv2">
          МЫ <span>эксперты</span> В СФЕРЕ <span>УГОЛОВНОГО ПРАВА</span> и{" "}
          <span>ГРАЖДАНСКОГО ПРАВА</span>
        </div>

        <div className="srv3">С чем мы можем вам помочь?</div>
        <div className="srv4">Гражданские дела</div>
        <button onClick={back} className="srv5">
          Назад
        </button>
        <div className="service-page-cards">
          {cards.slice().map((card, index) => (
            <Card
              image={card.image}
              header={card.header}
              description={card.description}
              formUp={formUp}
              pgn={pgn}
              pageNumber={card.pageNumber}
            />
          ))}
        </div>

        <img className="line" src={line} />
        <div className="srv6">Уголовные дела</div>
        <div className="service-page-cards num2">
          {ugcards.slice().map((card, index) => (
            <Card
              image={card.image}
              header={card.header}
              description={card.description}
              formUp={formUp}
              pgn={pgn}
              pageNumber={card.pageNumber}
            />
          ))}
        </div>
        <button onClick={back} className="srv5">
          Назад
        </button>
      </div>
    </div>
  );
};

export default ServPage;
