import "./Pages.css";

import Feed from "../Feedbacks/Feedbacks";
import Contact from "../Contacts";
import img from "../../Assets/pages/image3.png";
import logo from "../../Assets/logo.svg";

export default function Page3({ updateForm, updatePage }) {
  const form = () => {
    updateForm();
  };

  const page = () => {
    updatePage(-1);
  };
  return (
    <div>
      <div className="page">
        <div className="content">
          <img src={logo} className="logo" />
          <a href="https://wa.me/77051916994?text=" className="number">
            +7 705 191 6994
          </a>
          <div className="left-ff">
            <div className="ff1">наследственные споры</div>
            <div className="ff2">
              НАШ ЮРИСТ ПРЕДОСТАВЛЯЕТ СЛЕДУЮЩИЕ ЮРИДИЧЕСКИЕ УСЛУГИ:
            </div>
            <div className="ff3">
              Юрист по наследственным делам специализируется на решении
              вопросов, связанных с переходом прав собственности после смерти
              наследодателя. Он оказывает помощь в управлении и распределении
              наследственного имущества, защищает права клиентов в спорах о
              праве наследования. Доскональное знание семейного и
              наследственного права позволяет ему эффективно решать конфликты
              между наследниками, оспаривать завещания и представлять интересы
              клиентов в суде. Наследственные споры требуют особой аккуратности
              и компетенции для обеспечения законности процессов.
            </div>
            <div className="buttons">
              <button onClick={page} className="ff4">
                Назад к услугам
              </button>
              <button onClick={form} className="ff5">
                Заказать
              </button>
            </div>
          </div>
          <div className="right-ff">
            <img src={img} />
          </div>
        </div>
      </div>

      <div className="pril">
        <div className="pr1">Прилегающие Услуги</div>
        <div className="listing">
          <div className="lst">
            <span>
              1) Подготовка и оформление документов на наследственное имущество.
            </span>
          </div>
          <div className="lst">
            <span>
              2) Представление интересов клиентов в судебных разбирательствах по
              наследственным делам.
            </span>
          </div>
          <div className="lst">
            <span>
              3) Консультации по вопросам наследования и прав на обязательную
              долю.
            </span>
          </div>
          <div className="lst">
            <span>
              4) Оспаривание завещаний и признание завещаний недействительными.
            </span>
          </div>
          <div className="lst">
            <span>
              5) Урегулирование споров между наследниками, включая раздел
              имущества.
            </span>
          </div>
          <div className="lst">
            <span>
              6) Проведение анализа юридических документов и доказательств,
              связанных с наследством.
            </span>
          </div>
          <div className="lst">
            <span>
              7) Составление и проверка завещаний и иных завещательных
              документов.
            </span>
          </div>
          <div className="lst">
            <span>
              8) Помощь в оценке стоимости наследственного имущества и его
              распределении. Обеспечение соблюдения налоговых обязательств,
              связанных с наследством.
            </span>
          </div>
        </div>
      </div>

      <Feed></Feed>
      <Contact></Contact>
    </div>
  );
}
