import "./Pages.css";

import Feed from "../Feedbacks/Feedbacks";
import Contact from "../Contacts";
import img from "../../Assets/pages/image12.png";

import logo from "../../Assets/logo.svg";

export default function Page12({ updateForm, updatePage }) {
  const form = () => {
    updateForm();
  };

  const page = () => {
    updatePage(-1);
  };

  return (
    <div>
      <div className="page">
        <div className="content">
          <img src={logo} className="logo" />
          <a href="https://wa.me/77051916994?text=" className="number">
            +7 705 191 6994
          </a>
          <div className="left-ff">
            <div className="ff1">Экономические право</div>
            <div className="ff2">
              НАШ ЮРИСТ ПРЕДОСТАВЛЯЕТ СЛЕДУЮЩИЕ ЮРИДИЧЕСКИЕ УСЛУГИ:
            </div>
            <div className="ff3">
              Юридическая помощь по экономическим преступлениям включает широкий
              спектр услуг, начиная от юридических консультаций до полного
              представительства клиента в суде. Это охватывает защиту и
              представление в делах, связанных с незаконным
              предпринимательством, экономической контрабандой, уклонением от
              уплаты налогов и других обязательных платежей, а также
              преднамеренным банкротством и легализацией денежных средств,
              полученных преступным путём. Юристы анализируют документацию,
              разрабатывают защитную стратегию, ведут переговоры с
              контролирующими органами и обеспечивают защиту прав и интересов
              своих клиентов на всех этапах судебного разбирательства.
            </div>
            <div className="buttons">
              <button onClick={page} className="ff4">
                Назад к услугам
              </button>
              <button onClick={form} className="ff5">
                Заказать
              </button>
            </div>
          </div>
          <div className="right-ff">
            <img src={img} />
          </div>
        </div>
      </div>

      <div className="pril">
        <div className="pr1">Прилегающие Услуги</div>
        <div className="listing">
          <div className="lst">
            <span>1) Юридические консультации:</span> Предоставление информации
            о законодательстве, связанном с экономическими преступлениями, и
            оценка юридических рисков деятельности компании.
          </div>
          <div className="lst">
            <span>2) Представление интересов в налоговых спорах:</span> Помощь в
            разрешении споров с налоговыми органами, включая подготовку и подачу
            возражений на решения налоговых инспекций.
          </div>
          <div className="lst">
            <span>3) Защита в делах о банкротстве:</span> Представление
            интересов клиента в судебных процессах, связанных с преднамеренным
            банкротством и взысканием долгов.
          </div>
          <div className="lst">
            <span>4) Борьба с незаконным предпринимательством:</span> Подготовка
            защиты для предпринимателей, обвиняемых в ведении бизнеса без
            соответствующих лицензий или разрешений.
          </div>
          <div className="lst">
            <span>5) Урегулирование споров о контрабанде:</span> Помощь в
            урегулировании дел, связанных с обвинениями в контрабанде товаров и
            экономических санкций.
          </div>
          <div className="lst">
            <span>6) Обжалование штрафов и санкций:</span> Помощь в обжаловании
            и снижении административных и уголовных штрафов, наложенных на
            экономические преступления.
          </div>
          <div className="lst">
            <span>7) Защита от обвинений в легализации доходов: </span>{" "}
            Предоставление юридической защиты в случаях обвинения в отмывании
            денег и других формах легализации незаконных доходов.
          </div>
          <div className="lst">
            <span>8) Ведение переговоров и медиация:</span> Проведение
            положительных переговоров между сторонами для достижения
            взаимовыгодных соглашений и избежания судебных разбирательств..
          </div>
        </div>
      </div>

      <Feed></Feed>
      <Contact></Contact>
    </div>
  );
}
