import "./Pages.css";

import Feed from "../Feedbacks/Feedbacks";
import Contact from "../Contacts";
import img from "../../Assets/pages/image8.png";
import logo from "../../Assets/logo.svg";

export default function Page8({ updateForm, updatePage }) {
  const form = () => {
    updateForm();
  };

  const page = () => {
    updatePage(-1);
  };
  return (
    <div>
      <div className="page">
        <div className="content">
          <img src={logo} className="logo" />
          <a href="https://wa.me/77051916994?text=" className="number">
            +7 705 191 6994
          </a>
          <div className="left-ff">
            <div className="ff1">споры по инфобизнесу</div>
            <div className="ff2">
              НАШ ЮРИСТ ПРЕДОСТАВЛЯЕТ СЛЕДУЮЩИЕ ЮРИДИЧЕСКИЕ УСЛУГИ:
            </div>
            <div className="ff3">
              Споры в инфобизнесе могут возникать из-за различных проблем, таких
              как нарушение авторских прав, разногласия по поводу условий
              соглашений, мошенничество или конфликты с партнерами и клиентами.
              Юрист занимается разрешением таких споров связанных с их деловыми
              практиками. Он анализирует контракты и другие документы,
              разрабатывает стратегии для урегулирования разногласий,
              представляет интересы клиентов в судебных разбирательствах или
              переговорах, а также предлагает меры по защите интеллектуальной
              собственности. Основная цель юриста — обеспечить соблюдение прав и
              интересов клиентов, минимизировать риски и способствовать быстрому
              и эффективному разрешению конфликтов.
            </div>
            <div className="buttons">
              <button onClick={page} className="ff4">
                Назад к услугам
              </button>
              <button onClick={form} className="ff5">
                Заказать
              </button>
            </div>
          </div>
          <div className="right-ff">
            <img src={img} />
          </div>
        </div>
      </div>

      <div className="pril">
        <div className="pr1">Прилегающие Услуги</div>
        <div className="listing">
          <div className="lst">
            <span>1) Консультации по авторским правам:</span> Оказание помощи в
            вопросах защиты авторских прав на контент, такой как тексты, видео и
            программное обеспечение, а также предоставление рекомендаций по
            предотвращению нарушений..
          </div>
          <div className="lst">
            <span>2) Разработка и анализ контрактов:</span> Составление,
            проверка и редактирование договоров с партнерами, клиентами и
            поставщиками, чтобы обеспечить их юридическую обоснованность и
            защиту интересов клиента.
          </div>
          <div className="lst">
            <span>3) Представительство в спорах:</span> Защита интересов клиента
            в судебных разбирательствах или арбитраже, связанных с нарушением
            прав или контрактных обязательств.
          </div>
          <div className="lst">
            <span>4) Медиация и переговоры:</span> Участие в медиации и ведение
            переговоров между конфликтующими сторонами для достижения
            взаимоприемлемого решения и разрешения споров.
          </div>
          <div className="lst">
            <span>
              5) Разработка стратегий по защите интеллектуальной собственности:
            </span>{" "}
            Консультирование по регистрации товарных знаков, патентов и других
            объектов интеллектуальной собственности, а также защита от
            нарушений.
          </div>
          <div className="lst">
            <span>6) Урегулирование споров с клиентами и партнерами:</span>{" "}
            Разрешение конфликтов с клиентами или бизнес-партнерами, включая
            вопросы, связанные с выполнением обязательств, качеством услуг или
            продуктов.
          </div>
          <div className="lst">
            <span>7) Юридическое сопровождение рекламных кампаний:</span>{" "}
            Консультирование по соблюдению законодательства в области рекламы и
            маркетинга, чтобы избежать правовых рисков и споров.
          </div>
          <div className="lst">
            <span>8) Проверка соблюдения законодательства:</span> Проведение
            аудита бизнес-процессов и контента для обеспечения соответствия
            требованиям законодательства и предотвращения потенциальных правовых
            проблем.
          </div>
        </div>
      </div>

      <Feed></Feed>
      <Contact></Contact>
    </div>
  );
}
